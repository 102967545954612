import { Logout as LogoutIcon, Person, ViewList } from "@mui/icons-material"
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Chip,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material"
import { styled } from "@mui/material/styles"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import AuthComponent from "src/components/auth/AuthComponent"
import { useAuth } from "src/lib/auth/useAuth"

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  position: "fixed",
  elevation: 0,
  boxShadow: "none",
  zIndex: theme.zIndex.drawer + 2,
  height: "64px",
  backgroundColor: "white"
}))

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  backgroundColor: "white",
  borderBottom: `1px solid ${theme.palette.divider}`,
  color: "black",
  padding: "0 16px",
  minHeight: "64px"
}))

const Navbar: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const navigate = useNavigate()
  const { user, logOut, handle: userHandle, loading } = useAuth()
  const [accountsMenuAnchor, setAccountsMenuAnchor] =
    useState<null | HTMLElement>(null)
  const isAccountsMenuOpen = Boolean(accountsMenuAnchor)

  const openAccountsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAccountsMenuAnchor(event.currentTarget)
  }

  const closeAccountsMenu = () => {
    setAccountsMenuAnchor(null)
  }

  const signOut = async () => {
    setAccountsMenuAnchor(null)
    await logOut()
    navigate("/")
  }

  const Logo = (
    <Button
      onClick={() => navigate("/")}
      sx={{ padding: isMobile ? "4px" : "8px" }}>
      <Box sx={{ height: "30px" }}>
        <img
          src="/images/logo_only_blue.svg"
          alt="Sortdesk company logo"
          style={{ width: "auto", height: "100%" }}
        />
      </Box>
      <Chip
        label="BETA"
        color="primary"
        variant="outlined"
        sx={{
          backgroundColor: "white",
          border: "none",
          marginLeft: "0.5em",
          height: "2em",
          "& span": {
            paddingLeft: "0.7em",
            paddingRight: "0.7em"
          },
          marginTop: "0.2em"
        }}
      />
    </Button>
  )

  const UserMenu = (
    <Menu
      anchorEl={accountsMenuAnchor}
      id="account-menu"
      open={isAccountsMenuOpen}
      onClose={closeAccountsMenu}
      onClick={closeAccountsMenu}
      sx={{ paddingTop: "0px" }}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0
            }
          }
        }
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
      <Typography
        sx={{
          pl: "10px",
          pr: "10px",
          pt: "3px",
          pb: "5px",
          background: "#0d47a1",
          color: "white",
          minWidth: "150px"
        }}>
        <strong>{user?.displayName || user?.email}</strong>
      </Typography>
      {isMobile && user && (
        <MenuItem onClick={() => navigate(`/projects/${userHandle}`)}>
          <ListItemIcon>
            <ViewList fontSize="small" />
          </ListItemIcon>
          My Projects
        </MenuItem>
      )}
      <MenuItem onClick={() => navigate(`/account-settings`)}>
        <ListItemIcon>
          <Person fontSize="small" />
        </ListItemIcon>
        Account settings
      </MenuItem>

      <MenuItem onClick={signOut}>
        <ListItemIcon>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
        Sign out
      </MenuItem>
    </Menu>
  )

  return (
    <StyledAppBar>
      <StyledToolbar>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between"
          }}>
          {Logo}

          {!isMobile && (
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)"
              }}>
              {user && userHandle && (
                <Button
                  onClick={() => navigate(`/projects/${userHandle}`)}
                  variant="outlined"
                  color="primary"
                  startIcon={<ViewList />}>
                  My Projects
                </Button>
              )}
            </Box>
          )}

          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {!user && !loading && (
              <>
                {!isMobile && (
                  <Typography
                    sx={(theme) => ({
                      color: theme.palette.text.secondary,
                      marginRight: "1em"
                    })}>
                    Start using IDS Hub for free
                  </Typography>
                )}
                <AuthComponent />
              </>
            )}

            {user && (
              <Tooltip title="Account settings">
                <IconButton
                  onClick={openAccountsMenu}
                  size="small"
                  aria-controls={
                    isAccountsMenuOpen ? "account-menu" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={isAccountsMenuOpen ? "true" : undefined}>
                  <Avatar
                    variant="rounded"
                    sx={{ bgcolor: "#0d47a1", width: 40, height: 32 }}>
                    {user.displayName?.[0] || user.email?.[0]}
                  </Avatar>
                </IconButton>
              </Tooltip>
            )}
          </Box>

          {UserMenu}
        </Box>
      </StyledToolbar>
    </StyledAppBar>
  )
}

export default Navbar
