import { Box, Button, Stack, Typography } from "@mui/material"
import {
  isRouteErrorResponse,
  useNavigate,
  useRouteError
} from "react-router-dom"
import Navbar from "src/layout/Navbar"

const ErrorBoundary = () => {
  const error = useRouteError()
  const navigate = useNavigate()

  const getErrorMessage = () => {
    if (isRouteErrorResponse(error)) {
      if (error.status === 404) {
        return "Sorry, the page you are looking for does not exist."
      }
      return error.statusText || error.data?.message || "Something went wrong"
    }
    if (error instanceof Error) {
      return error.message
    }
    return "An unexpected error occurred"
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Navbar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pt: "64px",
          minHeight: "calc(100vh - 64px)",
          backgroundColor: (theme) => theme.palette.background.default,
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}>
        <Stack
          spacing={4}
          alignItems="center"
          sx={{
            maxWidth: "sm",
            width: "100%",
            textAlign: "center",
            py: 4
          }}>
          <Typography
            variant="h1"
            component="h1"
            sx={{
              fontWeight: 800,
              fontSize: "4rem"
            }}>
            {isRouteErrorResponse(error) && error.status === 404
              ? "404"
              : "Oops!"}
          </Typography>
          <Typography
            variant="h3"
            component="h2"
            sx={{
              fontWeight: 700
            }}>
            {isRouteErrorResponse(error) && error.status === 404
              ? "Page Not Found"
              : "Something went wrong"}
          </Typography>
          <Typography variant="h6" color="text.secondary">
            {getErrorMessage()}
          </Typography>
          <Stack direction="row" spacing={2} justifyContent="center">
            <Button
              variant="contained"
              onClick={() => navigate(-1)}
              sx={{
                textTransform: "none",
                minWidth: 120
              }}>
              Go Back
            </Button>
            <Button
              variant="outlined"
              onClick={() => navigate("/")}
              sx={{
                textTransform: "none",
                minWidth: 120
              }}>
              Go Home
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Box>
  )
}

export default ErrorBoundary
