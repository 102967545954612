export const QUICKSTART_PREFERENCE_KEY = "ids-hub-quickstart-skipped"

export const getQuickstartPreference = (): boolean => {
  try {
    return localStorage.getItem(QUICKSTART_PREFERENCE_KEY) === "true"
  } catch {
    return false
  }
}

export const setQuickstartPreference = (skipped: boolean): void => {
  try {
    localStorage.setItem(QUICKSTART_PREFERENCE_KEY, skipped.toString())
  } catch {
    console.warn("Failed to save quickstart preference")
  }
}
