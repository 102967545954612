import { Box, useTheme } from "@mui/material"
import { keyframes } from "@mui/system"
import React from "react"

interface LoaderProps {
  size?: number
  color?: string
  fullScreen?: boolean
}

const pulse = keyframes`
  0% {
    opacity: 0.3;
    transform: scale(0.95);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    opacity: 0.3;
    transform: scale(0.95);
  }
`

const AnimatedLoader: React.FC<LoaderProps> = ({
  size = 48,
  color,
  fullScreen = false
}) => {
  const theme = useTheme()
  const logoColor = color || theme.palette.primary.main

  return (
    <Box
      sx={{
        position: fullScreen ? "fixed" : "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.palette.background.default
      }}>
      <Box
        sx={{
          width: size,
          height: size,
          display: "inline-flex",
          animation: `${pulse} 2s ease-in-out infinite`
        }}>
        <svg
          viewBox="0 0 162 134.8"
          style={{
            width: "100%",
            height: "100%"
          }}>
          <path
            fill={logoColor}
            d="M69.5,134.8H30.2l-27-27c-4.2-4.2-4.2-11.1,0-15.3l12-12L69.5,134.8z"
          />
          <path
            fill={logoColor}
            d="M133,134.8H93.7L3.2,44.4C-1,40.1-1,33.3,3.2,29l12-12L133,134.8z"
          />
          <path
            fill={logoColor}
            d="M157.1,110.7l-12,12L27.3,4.9h39.3l90.5,90.5C161.3,99.7,161.3,106.5,157.1,110.7z"
          />
          <path
            fill={logoColor}
            d="M157.2,47.3l-12,12L90.9,4.9h39.3l27,27C161.4,36.2,161.4,43.1,157.2,47.3z"
          />
        </svg>
      </Box>
    </Box>
  )
}

export default AnimatedLoader
