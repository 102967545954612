import { Box } from "@mui/material"
import { Outlet } from "react-router-dom"
import { BreadcrumbsNav } from "./BreadcrumbsNav"
import Navbar from "./Navbar"

const NAVBAR_HEIGHT = "64px"

const BaseLayout = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column"
      }}>
      {/* Fixed navbar */}
      <Box sx={{ height: NAVBAR_HEIGHT, flexShrink: 0 }}>
        <Navbar />
      </Box>

      {/* Scrollable content area */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
          backgroundColor: (theme) => theme.palette.background.default
        }}>
        {/* Prevent layout shift caused by scrollbars */}
        <Box
          sx={{
            width: "100vw",
            display: "flex",
            justifyContent: "center"
          }}>
          <Box
            sx={{
              width: "100%",
              maxWidth: (theme) => theme.breakpoints.values.lg
            }}>
            <BreadcrumbsNav />
          </Box>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column"
          }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  )
}

export default BaseLayout
