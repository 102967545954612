import { Alert, Button, useMediaQuery, useTheme } from "@mui/material"
import React, { useState } from "react"

import { useAuth } from "src/lib/auth/useAuth"
import LoginModal from "./LoginModal"

interface AuthComponentProps {
  size?: "small" | "medium" | "large"
  buttonText?: string
  buttonIcon?: React.ReactNode
}

const AuthComponent: React.FC<AuthComponentProps> = ({
  size = "small",
  buttonText = "Sign Up",
  buttonIcon
}) => {
  const { error } = useAuth()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <>
      <Button
        variant="contained"
        size={isMobile ? "medium" : size}
        color="primary"
        startIcon={buttonIcon}
        onClick={() => setIsModalOpen(true)}
        sx={{
          width: isMobile ? "100%" : "auto"
        }}>
        {buttonText}
      </Button>
      <LoginModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      {error && (
        <Alert severity="error" sx={{ mt: 2, width: "100%" }}>
          Authentication error: {error.message}
        </Alert>
      )}
    </>
  )
}

export default AuthComponent
