import React from "react"
import { useAuth } from "src/lib/auth/useAuth"
import { DelayedFallback } from "./DelayedFallback"

interface AuthWrapperProps {
  children: React.ReactNode
  fallback?: React.ReactNode
}

export const AuthWrapper: React.FC<AuthWrapperProps> = ({
  children,
  fallback
}) => {
  const { loading, handleLoading } = useAuth()

  if (loading || handleLoading) {
    return fallback || <DelayedFallback />
  }

  return <>{children}</>
}
