import { httpsCallable } from "firebase/functions"
import { functions } from "../firebase/firebaseInitialize"

interface HandleVerificationResult {
  isValid: boolean
  reason: string | null
}

interface HandleUpdateResult {
  success: boolean
  handle: string
}

export const generateInitialHandle = async (): Promise<string> => {
  const generateHandleFunction = httpsCallable(
    functions,
    "generateUniqueHandle"
  )
  try {
    const result = await generateHandleFunction()
    return (result.data as { handle: string }).handle
  } catch (error) {
    console.error("Error generating initial handle:", error)
    throw error
  }
}

export const verifyHandle = async (
  handle: string
): Promise<HandleVerificationResult> => {
  const verifyHandleFunction = httpsCallable(functions, "verifyUniqueHandle")
  try {
    const result = await verifyHandleFunction({ handle })
    return result.data as HandleVerificationResult
  } catch (error) {
    console.error("Error verifying handle:", error)
    throw error
  }
}

export const setUserHandle = async (handle: string): Promise<string> => {
  const updateHandleFunction = httpsCallable(functions, "updateHandle")
  try {
    const result = await updateHandleFunction({ handle })
    return (result.data as HandleUpdateResult).handle
  } catch (error) {
    console.error("Error setting user handle:", error)
    throw error
  }
}

// Custom hook for handle validation
export const useHandleValidation = () => {
  const validateHandle = async (
    handle: string
  ): Promise<{
    isValid: boolean
    message?: string
  }> => {
    try {
      const result = await verifyHandle(handle)
      return {
        isValid: result.isValid,
        message: result.reason || undefined
      }
    } catch (error) {
      return {
        isValid: false,
        message: "Error validating handle"
      }
    }
  }

  return { validateHandle }
}
