import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import {
  Box,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Typography
} from "@mui/material"
import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { useBreadcrumbs } from "src/lib/breadcrumbs/useBreadcrumbs"

export const BreadcrumbsNav: React.FC = () => {
  const breadcrumbs = useBreadcrumbs()

  if (breadcrumbs.length <= 1) {
    return null
  }

  return (
    <Box
      sx={{
        px: { xs: 2, sm: 3 },
        pt: 2
      }}>
      <MuiBreadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb">
        {breadcrumbs.map((breadcrumb, _index) => {
          const isLast = breadcrumb.isLast

          return isLast ? (
            <Typography
              key={breadcrumb.path}
              color="text.primary"
              sx={{
                fontWeight: 500,
                fontSize: { xs: "0.875rem", sm: "1rem" }
              }}>
              {breadcrumb.label}
            </Typography>
          ) : (
            <Link
              key={breadcrumb.path}
              component={RouterLink}
              to={breadcrumb.path}
              color="inherit"
              sx={{
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "underline"
                },
                fontSize: { xs: "0.875rem", sm: "1rem" }
              }}>
              {breadcrumb.label}
            </Link>
          )
        })}
      </MuiBreadcrumbs>
    </Box>
  )
}
