import CloseIcon from "@mui/icons-material/Close"
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Link,
  Tab,
  Tabs,
  Typography
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

import {
  initializeAuthSettings,
  initializeTelemetry,
  isPasswordStrong,
  isSignInDataValid,
  isSignUpDataValid
} from "src/lib/auth/authHelpers"
import { useAuth } from "src/lib/auth/useAuth"
import { INPUTTYPES, inputTypeProps } from "src/lib/common/types"
import { BoxCol } from "../common/BoxCol"
import { BoxRow } from "../common/BoxRow"
import { Heavy } from "../common/Heavy"
import { OAuthButton } from "../common/OAuthButton"
import { PasswordAlert } from "../common/PasswordAlert"
import { TransactionTextField } from "../common/TransactionTextField"

interface LoginModalProps {
  isOpen: boolean
  onClose: () => void
}

export default function LoginModal({ isOpen, onClose }: LoginModalProps) {
  const theme = useTheme()
  const navigate = useNavigate()
  const [currentTab, setTab] = useState<"register" | "login">("register")
  const [loginName, setLoginName] = useState("")
  const [loginEmail, setLoginEmail] = useState("")
  const [loginPassword, setLoginPassword] = useState("")
  const [loginNewPassword, setLoginNewPassword] = useState("")
  const [loginNewPasswordConfirmation, setLoginNewPasswordConfirmation] =
    useState("")
  const [wantsViewerNews, setWantsViewerNews] = useState(false)
  const [loginMessage, setLoginMessage] = useState("")
  const [loginSeverity, setLoginSeverity] = useState<
    "error" | "success" | "info" | "warning"
  >("info")

  const {
    signInWithGoogle,
    signInWithMicrosoft,
    signUpWithEmail,
    signInWithEmail,
    resetPassword
  } = useAuth()

  const isLoginPassMatch = loginNewPassword === loginNewPasswordConfirmation
  const isNewPasswordStrong = isPasswordStrong(loginNewPassword)
  const isLoginSignUpActive = isSignUpDataValid(
    loginNewPassword,
    loginNewPasswordConfirmation,
    loginName,
    loginEmail
  )
  const isLoginSignInActive = isSignInDataValid(loginPassword, loginEmail)

  const switchToTab = (name: "register" | "login") => {
    setLoginMessage("")
    setTab(name)
  }

  const focusField = (field: INPUTTYPES) => {
    const fields = document.getElementsByName(inputTypeProps[field].name)
    if (fields[0]) (fields[0] as HTMLElement).focus()
  }

  const redirectToHome = () => {
    navigate("/")
  }

  const commonInputProps = {
    required: true,
    enabled: true,
    editEnabled: true,
    fullWidth: true,
    showButtons: false
  }

  const handleRegister = async () => {
    if (!isLoginSignUpActive) {
      setLoginMessage(
        "Please check your input and ensure your password is strong."
      )
      setLoginSeverity("error")
      return
    }
    try {
      setLoginMessage("Registering...")
      setLoginSeverity("info")
      await signUpWithEmail(loginEmail, loginNewPassword, loginName)
      setLoginMessage("Registration successful!")
      setLoginSeverity("success")
      redirectToHome()
    } catch (error) {
      setLoginMessage((error as Error).message)
      setLoginSeverity("error")
    }
  }

  const handleLogin = async () => {
    if (!isLoginSignInActive) {
      setLoginMessage("Invalid email or password.")
      setLoginSeverity("error")
      return
    }
    try {
      setLoginMessage("Logging in...")
      setLoginSeverity("info")
      await signInWithEmail(loginEmail, loginPassword)
      setLoginMessage("Login successful!")
      setLoginSeverity("success")
      redirectToHome()
    } catch (error) {
      setLoginMessage((error as Error).message)
      setLoginSeverity("error")
    }
  }

  const handleGoogleSignIn = async () => {
    initializeAuthSettings()
    initializeTelemetry()
    try {
      await signInWithGoogle()
      redirectToHome()
    } catch (error) {
      setLoginMessage((error as Error).message)
      setLoginSeverity("error")
    }
  }

  const handleMicrosoftSignIn = async () => {
    initializeAuthSettings()
    initializeTelemetry()
    try {
      await signInWithMicrosoft()
      redirectToHome()
    } catch (error) {
      setLoginMessage((error as Error).message)
      setLoginSeverity("error")
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      sx={{ p: 2 }}
      onClose={onClose}
      open={isOpen}>
      <DialogTitle>
        <Tabs
          value={currentTab}
          onChange={(_e, newValue) => switchToTab(newValue)}
          aria-label="account tabs">
          <Tab value="register" label="SIGN UP" />
          <Tab value="login" label="SIGN IN" />
        </Tabs>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: theme.spacing(1),
          top: theme.spacing(1),
          color: (theme) => theme.palette.grey[500]
        }}>
        <CloseIcon />
      </IconButton>
      {currentTab === "register" ? (
        <DialogContent dividers sx={{ maxHeight: 900, textAlign: "justify" }}>
          {loginMessage && (
            <Alert severity={loginSeverity} onClose={() => setLoginMessage("")}>
              {loginMessage}
            </Alert>
          )}
          <Typography
            component="div"
            sx={{
              lineHeight: "2em"
            }}>
            <FormControl fullWidth>
              <TransactionTextField
                {...commonInputProps}
                autoFocus
                type={INPUTTYPES.NAME}
                value={loginName}
                onChange={(e) => setLoginName(e.target.value)}
                onSave={() => focusField(INPUTTYPES.EMAIL)}
              />
              <TransactionTextField
                {...commonInputProps}
                type={INPUTTYPES.EMAIL}
                value={loginEmail}
                onChange={(e) => setLoginEmail(e.target.value)}
                onSave={() => focusField(INPUTTYPES.NEWPASSWORD)}
              />
              <TransactionTextField
                {...commonInputProps}
                type={INPUTTYPES.NEWPASSWORD}
                isValidationError={
                  !isNewPasswordStrong && loginNewPassword.length > 0
                }
                value={loginNewPassword}
                onChange={(e) => setLoginNewPassword(e.target.value)}
                onSave={() => focusField(INPUTTYPES.NEWPASSWORDCONFIRMATION)}
              />
              {!isNewPasswordStrong && loginNewPassword.length > 0 && (
                <PasswordAlert />
              )}
              <TransactionTextField
                {...commonInputProps}
                type={INPUTTYPES.NEWPASSWORDCONFIRMATION}
                isValidationError={
                  !isLoginPassMatch && loginNewPasswordConfirmation.length > 0
                }
                saveEnabled={isLoginSignUpActive}
                value={loginNewPasswordConfirmation}
                onChange={(e) =>
                  setLoginNewPasswordConfirmation(e.target.value)
                }
                onSave={handleRegister}
              />
              <BoxRow
                sx={{
                  alignItems: "flex-start",
                  gap: theme.spacing(1),
                  cursor: "pointer",
                  marginTop: theme.spacing(2),
                  marginBottom: theme.spacing(2)
                }}
                onClick={() => setWantsViewerNews(!wantsViewerNews)}>
                <Checkbox
                  sx={{ padding: 0, paddingTop: 0.25 }}
                  checked={wantsViewerNews}
                />
                <BoxCol sx={{ paddingRight: theme.spacing(1) }}>
                  <Typography
                    sx={{
                      textAlign: "justify",
                      textWrap: "wrap",
                      [theme.breakpoints.down("sm")]: {
                        textAlign: "left"
                      }
                    }}
                    component="div">
                    I want to receive <b>email updates</b> about{" "}
                    <Heavy>new features</Heavy> (<b>no spam, we promise</b>).
                  </Typography>
                </BoxCol>
              </BoxRow>
              <Button
                variant="contained"
                type="submit"
                disabled={!isLoginSignUpActive}
                onClick={handleRegister}>
                SIGN UP
              </Button>
            </FormControl>
            <Box
              sx={{ display: "flex", justifyContent: "space-around", mt: 2 }}>
              <Link
                component="button"
                onClick={() => switchToTab("login")}
                sx={{ textAlign: "center" }}>
                I already have an account
              </Link>
            </Box>
            <br />
            <BoxCol>
              <OAuthButton onClick={handleGoogleSignIn} alt="Google sign in">
                <img
                  src="/signin-assets/google/light/web_light_sq_SI.svg"
                  alt="Google sign in"
                  style={{ width: 191, height: 46 }}
                />
              </OAuthButton>
              <OAuthButton
                onClick={handleMicrosoftSignIn}
                alt="Microsoft sign in">
                <img
                  src="/signin-assets/microsoft/ms-symbollockup_signin_light.svg"
                  alt="Microsoft sign in"
                  style={{ width: 191, height: 46 }}
                />
              </OAuthButton>
            </BoxCol>
            <Box sx={{ mt: 2, textAlign: "center", lineHeight: "1em" }}>
              <Heavy>
                By clicking <b>SIGN UP</b> or <b>Sign in</b> through a third
                party you accept Sortdesk's{" "}
                <Link href="/full-terms-account" target="_blank">
                  Full Terms of Use
                </Link>
              </Heavy>
            </Box>
          </Typography>
        </DialogContent>
      ) : (
        <DialogContent dividers sx={{ maxHeight: 600, textAlign: "justify" }}>
          {loginMessage && (
            <Alert severity={loginSeverity} onClose={() => setLoginMessage("")}>
              {loginMessage}
            </Alert>
          )}
          <Typography component="div" sx={{ lineHeight: "2em" }}>
            <FormControl fullWidth>
              <TransactionTextField
                {...commonInputProps}
                autoFocus
                type={INPUTTYPES.EMAIL}
                value={loginEmail}
                onChange={(e) => setLoginEmail(e.target.value)}
                onSave={() => focusField(INPUTTYPES.PASSWORD)}
              />
              <TransactionTextField
                {...commonInputProps}
                type={INPUTTYPES.PASSWORD}
                saveEnabled={!!isLoginSignInActive}
                value={loginPassword}
                onChange={(e) => setLoginPassword(e.target.value)}
                onSave={handleLogin}
              />
              <Button
                variant="contained"
                disabled={!isLoginSignInActive}
                onClick={handleLogin}>
                SIGN IN
              </Button>
            </FormControl>
            <br />
            <br />
            <br />
            <BoxCol>
              <OAuthButton onClick={handleGoogleSignIn} alt="Google sign in">
                <img
                  src="/signin-assets/google/light/web_light_sq_SI.svg"
                  alt="Google sign in"
                  style={{ width: 191, height: 46 }}
                />
              </OAuthButton>
              <OAuthButton
                onClick={handleMicrosoftSignIn}
                alt="Microsoft sign in">
                <img
                  src="/signin-assets/microsoft/ms-symbollockup_signin_light.svg"
                  alt="Microsoft sign in"
                  style={{ width: 191, height: 46 }}
                />
              </OAuthButton>
            </BoxCol>
            <Box
              sx={{ display: "flex", justifyContent: "space-around", mt: 2 }}>
              <Link
                component="button"
                onClick={() => switchToTab("register")}
                sx={{ textAlign: "center" }}>
                Don't have an account yet? Sign up
              </Link>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-around", mt: 1 }}>
              <Link
                component="button"
                onClick={() => resetPassword(loginEmail)}
                sx={{ textAlign: "center" }}>
                Forgot password? Send reset email
              </Link>
            </Box>
          </Typography>
        </DialogContent>
      )}
    </Dialog>
  )
}
