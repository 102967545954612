import { Typography } from "@mui/material"
import { ReactNode } from "react"

export const Heavy = ({ children }: { children: ReactNode }) => {
  return (
    <Typography sx={{ fontWeight: 500 }} component="span">
      {children}
    </Typography>
  )
}
