import { Button, ButtonProps } from "@mui/material"
import React from "react"

interface OAuthButtonProps extends ButtonProps {
  onClick: () => void
  alt: string
}

export const OAuthButton: React.FC<OAuthButtonProps> = ({
  onClick,
  alt,
  children,
  ...props
}) => (
  <Button onClick={onClick} {...props}>
    {children}
  </Button>
)
