import { Alert, List, Typography } from "@mui/material"
import { Heavy } from "./Heavy"

export const PasswordAlert = () => (
  <Alert severity="error">
    <Typography component="div">
      The password must:
      <List sx={{ listStyleType: "disc", ml: 3, lineHeight: "1.8" }}>
        <li>
          be <b>8 to 20 characters</b> long
        </li>
        <li>
          use <b>at least 3</b> of the following <b>classes</b>:
          <ul>
            <li>
              <Heavy>lower</Heavy> case letters
            </li>
            <li>
              <Heavy>upper</Heavy> case letters
            </li>
            <li>
              <Heavy>number</Heavy>(s)
            </li>
            <li>
              <Heavy>special character</Heavy>(s)
            </li>
          </ul>
        </li>
      </List>
    </Typography>
  </Alert>
)
