export enum INPUTTYPES {
  EMAIL = "email",
  NAME = "name",
  PASSWORD = "password",
  NEWPASSWORD = "newPassword",
  NEWPASSWORDCONFIRMATION = "newPasswordConfirmation"
}

export enum AUTHPROVIDERS {
  MICROSOFT = "microsoft.com",
  GOOGLE = "google.com",
  EMAIL = "email"
}

export const inputTypeProps: { [key: string]: any } = {
  email: {
    label: "Email",
    name: "email",
    autoComplete: "email"
  },
  name: {
    label: "Name",
    name: "name",
    autoComplete: "name"
  },
  password: {
    label: "Password",
    name: "password",
    type: "password",
    autoComplete: "password"
  },
  newPassword: {
    label: "New Password",
    name: "newPassword",
    type: "password",
    autoComplete: "new-password"
  },
  newPasswordConfirmation: {
    label: "New Password Confirmation",
    name: "newPasswordConfirmation",
    type: "password",
    autoComplete: "new-password"
  }
}
