import React from "react"
import { Navigate, useLocation } from "react-router-dom"
import { useAuth } from "src/lib/auth/useAuth"

interface GuardProps {
  children: React.ReactNode
}

interface HomeRouteProps {
  authenticatedComponent: React.ReactNode
  unauthenticatedComponent: React.ReactNode
}

export const ProtectedRoute: React.FC<GuardProps> = ({ children }) => {
  const { user, loading } = useAuth()
  const location = useLocation()

  if (loading) return null

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return <>{children}</>
}

export const RedirectToHome: React.FC<GuardProps> = ({ children }) => {
  const { user, loading } = useAuth()

  if (loading) return null

  if (user) {
    return <Navigate to="/" replace />
  }

  return <>{children}</>
}

export const RequireHandle: React.FC<GuardProps> = ({ children }) => {
  const { user, loading, needsHandle } = useAuth()
  const location = useLocation()

  if (loading) return null

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  if (needsHandle) {
    return <Navigate to="/create-handle" replace />
  }

  return <>{children}</>
}

export const RequireEmailVerification: React.FC<GuardProps> = ({
  children
}) => {
  const { user, loading, needsHandle } = useAuth()
  const location = useLocation()

  if (loading) return null

  // First, check if user needs handle
  if (needsHandle) {
    return <Navigate to="/create-handle" replace />
  }

  const isEmailPasswordUser = user?.providerData[0]?.providerId === "password"
  if (user && !needsHandle && isEmailPasswordUser && !user.emailVerified) {
    return <Navigate to="/verify-email" replace />
  }

  // User is either verified, using OAuth, or doesn't need verification yet
  if (user && (!isEmailPasswordUser || user.emailVerified)) {
    return <>{children}</>
  }

  return <Navigate to="/login" state={{ from: location }} replace />
}

export const HandleCreationGuard: React.FC<GuardProps> = ({ children }) => {
  const { user, loading, needsHandle } = useAuth()

  if (loading) return null

  if (!user) {
    return <Navigate to="/login" replace />
  }

  if (!needsHandle) {
    return <Navigate to="/" replace />
  }

  return <>{children}</>
}

export const HomeRoute: React.FC<HomeRouteProps> = ({
  authenticatedComponent,
  unauthenticatedComponent
}) => {
  const { user, loading, needsHandle } = useAuth()

  if (loading) return null

  if (!user) {
    return <>{unauthenticatedComponent}</>
  }

  if (needsHandle) {
    return <Navigate to="/create-handle" replace />
  }

  // Check email verification for email/password users
  const isOAuthUser = user.providerData[0]?.providerId !== "password"
  if (!isOAuthUser && !user.emailVerified) {
    return <Navigate to="/verify-email" replace />
  }

  return <>{authenticatedComponent}</>
}
