export const initializeAuthSettings = () => {
  localStorage.setItem("acceptedTelemetry", "true")
  localStorage.setItem("acceptedToS", "true")
  // TODO: add any other settings that need to be initialized
}

// Placeholder for real telemetry initialization
export const initializeTelemetry = () => {
  console.log("Telemetry initialized")
  // TODO: add real telemetry initialization (if needed)
}

export const isPasswordStrong = (password: string): boolean => {
  return (
    password.length >= 8 &&
    password.length <= 20 &&
    [/[0-9]/, /[a-z]/, /[A-Z]/, /[@#$^&*+=]/].filter((regex) =>
      regex.test(password)
    ).length >= 3
  )
}

export const isSignUpDataValid = (
  password: string,
  confirmPassword: string,
  name: string,
  email: string
): boolean => {
  return (
    isPasswordStrong(password) &&
    password === confirmPassword &&
    name.length >= 3 &&
    email.length >= 3 &&
    email.includes("@")
  )
}

export const isSignInDataValid = (password: string, email: string): boolean => {
  return password.length >= 8 && email.length > 1
}
