import { connectAuthEmulator, getAuth } from "firebase/auth"
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore"
import { connectFunctionsEmulator, getFunctions } from "firebase/functions"
import { connectStorageEmulator, getStorage } from "firebase/storage"
import { app } from "./firebaseConfig"

const auth = getAuth(app)
const functions = getFunctions(app, "europe-west6")
const db = getFirestore(app)
const storage = getStorage(app)

if (import.meta.env.DEV) {
  connectAuthEmulator(auth, "http://localhost:9099")
  connectFunctionsEmulator(functions, "localhost", 5001)
  connectFirestoreEmulator(db, "localhost", 5002)
  connectStorageEmulator(storage, "localhost", 5004)
}

export { auth, db, functions, storage }
