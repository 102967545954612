import { getAuth, User } from "firebase/auth"
import { doc, getDoc } from "firebase/firestore"
import { useCallback, useEffect, useState } from "react"
import { db } from "../firebase/firebaseInitialize"
import {
  deleteAccount,
  logOut,
  resendVerificationEmail,
  resetPassword,
  setupAuthListener,
  signInWithEmail,
  signInWithGoogle,
  signInWithMicrosoft,
  signUpWithEmail
} from "./firebaseAuth"
import { generateInitialHandle, setUserHandle } from "./userHandle"

interface AuthState {
  user: User | null
  loading: boolean
  error: Error | null
  handle: string | null
  needsHandle: boolean
  handleLoading: boolean
  signupSource: string | null
  cachedDisplayName: string | null
}

interface CachedAuthState {
  user: {
    uid: string
    email: string | null
    displayName: string | null
  } | null
  handle: string | null
  signupSource: string | null
}

const AUTH_CACHE_KEY = "authState"

const getUserData = async (
  uid: string
): Promise<{
  handle: string | null
  signupSource: string | null
}> => {
  try {
    const userDoc = await getDoc(doc(db, "users", uid))
    if (userDoc.exists()) {
      const data = userDoc.data()
      return {
        handle: data.handle || null,
        signupSource: data.signupSource || null
      }
    }
    return { handle: null, signupSource: null }
  } catch (error) {
    console.error("Error fetching user data:", error)
    return { handle: null, signupSource: null }
  }
}

export const useAuth = () => {
  const [state, setState] = useState<AuthState>(() => {
    const cached = localStorage.getItem(AUTH_CACHE_KEY)
    const initialState: CachedAuthState = cached
      ? JSON.parse(cached)
      : { user: null, handle: null, signupSource: null }

    return {
      user: null,
      loading: true,
      error: null,
      handle: initialState.handle,
      needsHandle: false,
      handleLoading: true,
      signupSource: initialState.signupSource,
      cachedDisplayName: initialState.user?.displayName || null
    }
  })

  const refreshAuthState = useCallback(async () => {
    if (!state.user) return

    try {
      // Force reload the user
      await state.user.reload()
      // Get the fresh user object
      const auth = getAuth()
      const freshUser = auth.currentUser

      if (freshUser) {
        const { handle, signupSource } = await getUserData(freshUser.uid)

        // Update cache
        const cacheData: CachedAuthState = {
          user: {
            uid: freshUser.uid,
            email: freshUser.email,
            displayName: freshUser.displayName
          },
          handle,
          signupSource
        }
        localStorage.setItem(AUTH_CACHE_KEY, JSON.stringify(cacheData))

        setState((prev) => ({
          ...prev,
          user: freshUser, // Use the fresh user object
          handle,
          needsHandle: !handle,
          handleLoading: false,
          signupSource,
          cachedDisplayName: freshUser.displayName
        }))
      }
    } catch (error) {
      console.error("Error refreshing auth state:", error)
    }
  }, [state.user])

  useEffect(() => {
    const unsubscribe = setupAuthListener(async (user) => {
      if (user) {
        setState((prev) => ({ ...prev, loading: true }))
        try {
          const { handle, signupSource } = await getUserData(user.uid)

          // Update cache
          const cacheData: CachedAuthState = {
            user: {
              uid: user.uid,
              email: user.email,
              displayName: user.displayName
            },
            handle,
            signupSource
          }
          localStorage.setItem(AUTH_CACHE_KEY, JSON.stringify(cacheData))

          setState({
            user,
            loading: false,
            error: null,
            handle,
            needsHandle: !handle,
            handleLoading: false,
            signupSource,
            cachedDisplayName: user.displayName
          })
        } catch (error) {
          setState({
            user,
            loading: false,
            error: error as Error,
            handle: null,
            needsHandle: true,
            handleLoading: false,
            signupSource: null,
            cachedDisplayName: user.displayName
          })
        }
      } else {
        localStorage.removeItem(AUTH_CACHE_KEY)
        setState({
          user: null,
          loading: false,
          error: null,
          handle: null,
          needsHandle: false,
          handleLoading: false,
          signupSource: null,
          cachedDisplayName: null
        })
      }
    })

    return () => unsubscribe()
  }, [])

  const createHandle = async (customHandle?: string): Promise<string> => {
    setState((prev) => ({ ...prev, handleLoading: true }))
    try {
      let handle: string

      if (customHandle) {
        // If a custom handle is provided, use it
        handle = await setUserHandle(customHandle)
      } else {
        // Otherwise, generate and set a new handle
        const generatedHandle = await generateInitialHandle()
        handle = await setUserHandle(generatedHandle)
      }

      // Update cache and state
      if (state.user) {
        const cacheData: CachedAuthState = {
          user: {
            uid: state.user.uid,
            email: state.user.email,
            displayName: state.user.displayName
          },
          handle,
          signupSource: state.signupSource
        }
        localStorage.setItem(AUTH_CACHE_KEY, JSON.stringify(cacheData))
      }

      setState((prev) => ({
        ...prev,
        handle,
        needsHandle: false,
        handleLoading: false,
        error: null
      }))

      return handle
    } catch (error) {
      setState((prev) => ({
        ...prev,
        error: error as Error,
        handleLoading: false
      }))
      throw error
    }
  }

  return {
    ...state,
    createHandle,
    deleteAccount,
    signInWithGoogle,
    signInWithMicrosoft,
    signUpWithEmail,
    signInWithEmail,
    resetPassword,
    logOut,
    resendVerificationEmail,
    refreshAuthState,
    isEmailVerified: state.user?.emailVerified ?? false
  }
}
