import { RouterProvider } from "react-router-dom"
import ThemeRegistry from "./components/theme/ThemeRegistry"
import { router } from "./router/routes"
import "/src/config/theme/globals.css"

function App() {
  return (
    <ThemeRegistry>
      <RouterProvider router={router} />
    </ThemeRegistry>
  )
}

export default App
