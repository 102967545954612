import BorderColorIcon from "@mui/icons-material/BorderColor"
import CloseIcon from "@mui/icons-material/Close"
import SaveIcon from "@mui/icons-material/Save"
import { IconButton, TextField, TextFieldProps } from "@mui/material"
import { inputTypeProps, INPUTTYPES } from "src/lib/common/types"

export const TransactionTextField = ({
  value,
  enabled,
  editEnabled,
  saveEnabled,
  showButtons,
  isValidationError,
  type,
  onChange,
  onSave,
  onNext,
  onEdit,
  onCancel,
  ...rest
}: Partial<TextFieldProps> & {
  value: string
  enabled: boolean
  editEnabled?: boolean
  saveEnabled?: boolean
  isValidationError?: boolean
  showButtons?: boolean
  type: INPUTTYPES
  onChange: (e: any) => void
  onSave?: (e: any) => void
  onNext?: (e: any) => void
  onEdit?: (e: any) => void
  onCancel?: (e: any) => void
}) => {
  return (
    <TextField
      {...inputTypeProps[type]}
      variant="outlined"
      margin="normal"
      fullWidth
      disabled={!enabled}
      error={enabled && isValidationError}
      value={value}
      onChange={onChange}
      onKeyDown={(e) => {
        if (e.key === "Enter" && enabled) {
          if (saveEnabled ?? !isValidationError) {
            if (onSave) onSave(e)
          } else {
            if (onNext) onNext(e)
          }
        }
      }}
      InputProps={{
        endAdornment: showButtons !== false && (
          <>
            <IconButton
              disabled={!(enabled && (saveEnabled ?? !isValidationError))}
              color="primary"
              onClick={(e: any) => {
                if (onSave) onSave(e)
                e.stopPropagation()
              }}
              sx={{
                width: (theme) => theme.spacing(5),
                height: (theme) => theme.spacing(5) //theme.spacing(5)
              }}>
              <SaveIcon sx={{ display: enabled ? "block" : "none" }} />
            </IconButton>
            <IconButton
              color={editEnabled !== false ? "primary" : "default"}
              onClick={(e: any) => {
                if (enabled) {
                  if (onCancel) onCancel(e)
                } else {
                  if (onEdit) onEdit(e)
                }
                e.stopPropagation()
              }}
              sx={{
                width: (theme) => theme.spacing(5),
                height: (theme) => theme.spacing(5)
              }}>
              {enabled ? <CloseIcon /> : <BorderColorIcon />}
            </IconButton>
          </>
        )
      }}
      {...rest}
    />
  )
}
