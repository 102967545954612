import { PaletteColorOptions, createTheme } from "@mui/material/styles"

declare module "@mui/material/styles" {
  interface Palette {
    hoverBackground?: string
    borderUnselected?: string
    subtleGreyBorder?: string
    subtlerGreyBorder?: string
    hoverGreyBackground?: string
    inactiveGrey?: string
    greyText?: string
    selectedBackground?: string
    softFail?: string
    softPass?: string
    softBulletGrey?: string
    validationError?: string
    runGreen?: PaletteColorOptions
    cloudDoneGreen?: string
    cloudSyncingYellow?: string
    cloudDesyncedRed?: string
    notificationBadge: PaletteColorOptions
    blackInk: string
    blueAccentDark: string
    blueAccentMed: string
    blueAccentLight: string
    bgLightBlue: string
    bgLightGrey: string
    bgLighterGrey: string
  }
  interface PaletteOptions {
    hoverBackground?: string
    borderUnselected?: string
    subtleGreyBorder?: string
    subtlerGreyBorder?: string
    hoverGreyBackground?: string
    inactiveGrey?: string
    greyText?: string
    selectedBackground?: string
    softFail?: string
    softPass?: string
    softBulletGrey?: string
    validationError?: string
    runGreen?: PaletteColorOptions
    cloudDoneGreen?: string
    cloudSyncingYellow?: string
    cloudDesyncedRed?: string
    notificationBadge: PaletteColorOptions
    blackInk: string
    blueAccentDark: string
    blueAccentMed: string
    blueAccentLight: string
    bgLightBlue: string
    bgLightGrey: string
    bgLighterGrey: string
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    inline: true
  }

  interface ButtonBaseOwnProps {
    variant?: "inline"
  }
}

declare module "@mui/material/IconButton" {
  interface IconButtonOwnProps {
    variant?: "inline"
    // note: this can't be used inside the theme because there's no shouldForwardProp
    // to prevent it from reaching the DOM
    isCircled?: boolean
  }
}

declare module "@mui/material/Checkbox" {
  interface CheckboxProps {
    variant?: "inline"
  }
}

type spType = {
  spaciousV: number
  spacious2V: number
  spaciousH: number
  spacious2H: number
  compactV: number
  compactH: number
  compact2V: number
  compact2H: number
  gapTiny: number
  iUnit: number
  iUnit2: number
  iconSize: number
  sidepanelWidth: number
  sidebarWidth: number
  footerHeight: number
  headerHeight: number
  compactHeight: number
  modalWidth: number
}

const sp: spType = {
  // reused spacings
  // todo: use these where applicable
  spaciousV: 16,
  spacious2V: 18,
  spaciousH: 24,
  spacious2H: 32,
  compactV: 5,
  compactH: 10,
  compact2V: 8,
  compact2H: 14,
  gapTiny: 3,
  iUnit: 22, // interactive unit, radius of an IconButton
  iUnit2: 44, // interactive unit, diameter of an IconButton
  iconSize: 24,
  sidepanelWidth: 400,
  sidebarWidth: 56,
  footerHeight: 32,
  headerHeight: 65,
  compactHeight: 32,
  modalWidth: 550
}

const sppx = (Object.keys(sp) as Array<keyof spType>).reduce(
  (result, key) => ((result[key] = `${sp[key]}px`), result),
  {} as { [key: string]: string }
) as { [key in keyof spType]: string }

const vPaddings = {
  compact: {
    paddingTop: sppx.compactV,
    paddingBottom: sppx.compactV
  },
  compact2: {
    paddingTop: sppx.compact2V,
    paddingBottom: sppx.compact2V
  },
  spacious: {
    paddingTop: sppx.spaciousV,
    paddingBottom: sppx.spaciousV
  },
  spacious2: {
    paddingTop: sppx.spacious2V,
    paddingBottom: sppx.spacious2V
  }
}

const hPaddings = {
  compact: {
    paddingLeft: sppx.compactH,
    paddingRight: sppx.compactH
  },
  compact2: {
    paddingLeft: sppx.compact2H,
    paddingRight: sppx.compact2H
  },
  spacious: {
    paddingLeft: sppx.spaciousH,
    paddingRight: sppx.spaciousH
  },
  spacious2: {
    paddingLeft: sppx.spacious2H,
    paddingRight: sppx.spacious2H
  }
}

const paddings = {
  compact: { ...vPaddings.compact, ...hPaddings.compact },
  compact2: { ...vPaddings.compact2, ...hPaddings.compact2 },
  spacious: { ...vPaddings.spacious, ...hPaddings.spacious },
  spacious2: { ...vPaddings.spacious2, ...hPaddings.spacious2 }
}

export default {
  ...createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1050, // this one was increased for LandingSplash
        lg: 1200,
        xl: 1536
      }
    },
    palette: {
      primary: { main: "#0D47A1" },
      runGreen: {
        main: "#3ea03a",
        light: "#3ea03a",
        dark: "#3ea03a",
        contrastText: "#ffffff"
      },
      cloudDoneGreen: "#169505",
      cloudSyncingYellow: "#fbb604",
      cloudDesyncedRed: "#d43655",
      notificationBadge: {
        main: "#ff3d00",
        light: "#ff3d00",
        dark: "#ff3d00",
        contrastText: "#ffffff"
      },
      secondary: { main: "#F2F6F9" },
      hoverBackground: "rgb(239 245 255)",
      hoverGreyBackground: "rgba(0, 0, 0, 0.04)",
      borderUnselected: "rgba(13, 71, 161, 0.5)",
      subtleGreyBorder: "rgba(0, 0, 0, 0.12)",
      subtlerGreyBorder: "#eeeeee",
      inactiveGrey: "#767676",
      greyText: "#444444",
      selectedBackground: "rgb(239 245 255)",
      softBulletGrey: "#ddd",
      softFail: "#d17062",
      softPass: "#49a66c",
      validationError: "#d32f2f",
      blackInk: "#1c2629",
      blueAccentDark: "#1976d2",
      blueAccentMed: "#42a5f5",
      blueAccentLight: "#90caf9",
      bgLightBlue: "#e3f2fd",
      bgLightGrey: "#f2f6f9",
      // bgLighterGrey: "#fafcfd",
      bgLighterGrey: "#fdfdfe"
    },
    typography: {
      fontFamily: "Inter, Arial",
      body2: {
        fontSize: "1rem",
        padding: 0
      },
      body1: {
        padding: 0
      },
      button: {
        textTransform: "none"
      }
    },
    components: {
      MuiInputBase: {
        defaultProps: {
          // Needed to prevent adding a global style for every input field for performance
          disableInjectingGlobalStyles: true
        }
      },
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            minWidth: 0,
            ...(ownerState.size == "small" && {
              borderTopLeftRadius: "4px",
              borderTopRightRadius: "4px",
              borderBottomLeftRadius: "4px",
              borderBottomRightRadius: "4px",
              padding: "5px 10px",
              height: sppx.compactHeight,
              "& .MuiSvgIcon-root": {
                width: "18px",
                height: "18px"
              }
            })
          })
        }
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            height: "65px"
          }
        }
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            fontSize: "1rem",
            padding: 0,
            margin: 0
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            padding: 0,
            margin: 0
          },
          h6: {
            padding: 0,
            margin: 0
          }
        }
      },
      MuiLink: {
        styleOverrides: {
          root: {
            textUnderlineOffset: "0.25em"
          }
        }
      },
      MuiList: {
        styleOverrides: {
          root: {
            padding: 0
          }
        }
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            padding: "16px 24px"
          }
        }
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            padding: 0
          }
        }
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            width: "24px",
            height: "24px"
          }
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            boxSizing: "border-box",
            padding: "10px",
            ...(ownerState.variant === "inline" && {
              padding: 0
            })
          })
        }
      },
      MuiCheckbox: {
        defaultProps: {
          disableRipple: true
        },
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...(ownerState.variant === "inline" && {
              padding: 0,
              position: "relative"
            })
          })
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: "16px 24px"
          }
        }
      },
      MuiAutocomplete: {
        styleOverrides: {
          inputRoot: {
            borderRadius: "8px"
          }
        }
      }
    }
  }),
  sp,
  sppx,
  hPad: hPaddings,
  vPad: vPaddings,
  pad: paddings
}
