import { Box } from "@mui/material"
import React, { useEffect, useState } from "react"
import AnimatedLoader from "src/components/common/AnimatedLoader"

interface DelayedFallbackProps {
  delay?: number
  fullScreen?: boolean
}

export const DelayedFallback: React.FC<DelayedFallbackProps> = ({
  delay = 300,
  fullScreen = true
}) => {
  const [showLoading, setShowLoading] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setShowLoading(true), delay)
    return () => clearTimeout(timer)
  }, [delay])

  if (!showLoading) return null

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: fullScreen ? `calc(100vh - 64px)` : "100%",
        overflow: "hidden"
      }}>
      <AnimatedLoader fullScreen={false} />
    </Box>
  )
}
