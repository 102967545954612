import { matchPath } from "react-router-dom"

export interface BreadcrumbConfig {
  path: string
  label: string | ((params: any, auth: any) => string)
  parent?: string
}

export interface Breadcrumb {
  path: string
  label: string
  isLast: boolean
}

export const breadcrumbConfig: BreadcrumbConfig[] = [
  { path: "/", label: "Home" },
  {
    path: "/projects/:handle",
    label: (params, auth) =>
      params.handle === auth.handle
        ? "My Projects"
        : `${params.handle}'s Projects`,
    parent: "/"
  },
  {
    path: "/projects/:handle/create",
    label: "New Project",
    parent: "/projects/:handle"
  },
  {
    path: "/projects/:handle/:projectname",
    label: (params) => params.projectname,
    parent: "/projects/:handle"
  },
  {
    path: "/projects/:handle/:projectname/edit",
    label: "Edit",
    parent: "/projects/:handle/:projectname"
  },
  {
    path: "/account-settings",
    label: "Account Settings",
    parent: "/"
  }
]

// Helper function to build path with params
export const buildPath = (path: string, params: Record<string, string>) => {
  return Object.keys(params).reduce(
    (path, param) => path.replace(`:${param}`, params[param]),
    path
  )
}

// Helper function to match path and extract params
export const getPathMatch = (pathname: string, configPath: string) => {
  const match = matchPath(
    {
      path: configPath,
      end: true
    },
    pathname
  )
  return match
}
