import { useCallback, useMemo } from "react"
import { useLocation } from "react-router-dom"
import { useAuth } from "src/lib/auth/useAuth"
import {
  Breadcrumb,
  breadcrumbConfig,
  BreadcrumbConfig,
  buildPath,
  getPathMatch
} from "src/lib/breadcrumbs/BreadcrumbConfig"

export const useBreadcrumbs = () => {
  const location = useLocation()
  const auth = useAuth()

  const getBreadcrumbsForPath = useCallback(
    (pathname: string, config: BreadcrumbConfig[]): Breadcrumb[] => {
      const matchingConfig = config.find((cfg) =>
        getPathMatch(pathname, cfg.path)
      )
      if (!matchingConfig) {
        return []
      }

      // Get parameters from current path
      const match = getPathMatch(pathname, matchingConfig.path)
      const params = Object.fromEntries(
        Object.entries(match?.params || {}).map(([key, value]) => [
          key,
          value ?? ""
        ])
      )

      // Get label (text) for current breadcrumb
      const label =
        typeof matchingConfig.label === "function"
          ? matchingConfig.label(params, auth)
          : matchingConfig.label

      // Build actual path with parameters
      const resolvedPath = buildPath(matchingConfig.path, params)

      // If there's a parent path, recursively get parent breadcrumbs
      const parentBreadcrumbs = matchingConfig.parent
        ? getBreadcrumbsForPath(
            buildPath(matchingConfig.parent, params),
            config
          )
        : []

      // Add current breadcrumb
      return [
        ...parentBreadcrumbs,
        {
          path: resolvedPath,
          label,
          isLast: true
        }
      ].map((crumb, index, array) => ({
        ...crumb,
        isLast: index === array.length - 1
      }))
    },
    [auth]
  )

  const breadcrumbs = useMemo(
    () => getBreadcrumbsForPath(location.pathname, breadcrumbConfig),
    [location.pathname, getBreadcrumbsForPath]
  )

  return breadcrumbs
}
